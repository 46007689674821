/****** GOOGLE FONTS ******/

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWJ0bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFUZ0bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWZ0bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVp0bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFWp0bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFW50bf8pkAp6a.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirkOX-hpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirkOVuhpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirkOXuhpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirkOUehpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirkOXehpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirkOXOhpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOX-hpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOVuhpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOXuhpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOUehpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOXehpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOXOhpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/****** END GOOGLE FONTS ******/




body {
    background: #F4F4F4;
    font-size: 16px;
    line-height: 1.8em;
    font-family: 'Open Sans', sans-serif;
    color: black;
}

h1, h2, h3 {
  font-weight: bold;
  margin-bottom: 15px;
}

h1 {
    margin-top: 0px;
    font-size: 1.8em;
    line-height: 1.3em;
}

h2 {
    
}


h3 {

}

hr {
    border-top: 1px solid #d2d2d2;
    clear: both;
}

p {
    padding: 5px 0!important;
}

a {
  color: black;
  
}

a.underline {
  text-decoration: underline;
}

b, strong {
    font-weight: 600;
}

a:hover {
  color: red;
}

img {
  border-radius: 5px;
}

section {
  overflow: hidden;
  padding: 45px 15px;
}

.clearfix {
    clear: both;
}

.fa-btn {
            margin-right: 6px;
    
        }


.btn {
    border-radius: 1px;
    padding: 13px 22px;
    font-weight: 700;
    letter-spacing: 0.01em;
    font-size: 13px;
}

.btn.btn-primary {
    color: white;
    background: #0000FD;
    border: none;


}

.arrow-right-icon {
    float: right;
    height: 10px;
    margin-top: 5px;
    width: 10px;
    margin-left: 10px;
    background: url(/images/arrow-right-icon.png);
    background-repeat: no-repeat;
    background-size: 10px;

}

.align-center {
    text-align: center;
}



/* -------------------------------------------*/
/*  Sidebar
/* -------------------------------------------*/

#sidebar {
    display: none
}



/* -------------------------------------------*/
/*  CONTACT DETAILS FLAG
/* -------------------------------------------*/

.contact-details {
    display: none;
}



/* -------------------------------------------*/
/*  Navigation
/* -------------------------------------------*/

/* Navigation Bar */

.navbar {
  position: fixed;
    width: 100%;
    min-height: 75px;
    margin-bottom: 0;
    background: #0C131F; 
    border: none;
}



.navbar-nav {
    padding: 25px 0px;
}

.navbar-default .navbar-collapse {
    border: 0;
}


.navbar-default .navbar-nav > li > a:hover {
    color: #C91E1E;
}



/*** LOGO ***/
.navbar-default .navbar-brand {
    color: #777;
    width: 115px;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 0px;
    margin-left: 0px;
}


/* Hamburger Menu */
.navbar .navbar-toggle  {
    border: none;
    margin-top: 19px;
}

.navbar .navbar-toggle:hover  {
    background: transparent;
}

.navbar .navbar-toggle .icon-bar {
    background: white;
    width: 33px;
    height: 3px;
    border-radius: 0;
}


.navbar-toggle .icon-bar + .icon-bar {
    margin-top: 7px;
}


/* Menu */
.navbar-default .navbar-nav > li > a {
    color: white;
    font-weight: 600;
}


#header-contact-info {
      display: none;
      position: fixed;
      top: 87px;
      right: 0;
      z-index: 9999;
     /* width: 280px;*/
      padding: 15px 25px 15px 25px;
      color: white;
      /*background: rgba(12,19,31,0.9);*/
      background: rgb(201 30 30 / 97%);
      border-radius: 0px 0 0 5px;
  }


/* -------------------------------------------*/
/*  Content
/* -------------------------------------------*/


.container {
    padding-right: 25px;
    padding-left: 25px;
}

.content-wrapper {
    padding-top: 25px;
}

.content-wrapper .container {
   /* padding: 15px 25px;*/
    padding: 15px 0;
}

/* Begin Default Search */
.search-wrapper {
    
}

.search input {
    width: 75%;
    float: left;
    color: black;
    border: 2px solid #EBEBEB;
    padding: 8px 15px;
    margin-right: 15px;
}


.search button {
    float: right;
    width: calc(20% - 15px);
    padding: 14px;
    background: #C91E1E;
    border: none;
    border-radius: 5px;
    color: white;
}

.search button:hover {
    background: #EB3B3B;
}

/* Search Style: search-home */
.search-wrapper.search-home {
    padding: 13px 13px;
    background: rgba(0,0,0,0.4);
    border-radius: 10px;
    overflow: hidden;
    margin-top: 35px;

}

/* Search Style: search-shop */
.search-wrapper.search-shop {
    margin-bottom: 35px;
    overflow: hidden;
}

.search-wrapper.search-shop input {
  background: white;
  padding: 8px 15px;
  font-size: 1.3rem;
}

.search-wrapper.search-shop .search button {
    padding: 8px;
}

/* Search Style: search-header */

#toggle-header-search {
    position: absolute;
    top: 25px;
    right: 100px;
    color: white;
    font-size: 2.4rem;
    cursor: pointer;
}

.search-wrapper.search-header {
    display: none;
    width: 100%;
    float: none;
    position: fixed;
    margin-top: 59px;
    padding: 25px;
    background: rgba(12,19,31,0.95);
    left: 0;
    right: 0;
    top: 0;
}

.search-wrapper.search-header input {
 
  background: white;
  margin-right: 10px;
  padding: 7px 15px;
  font-size: 1.3rem;
  background: #EDEDED;
  width: calc(100% - 75px);
}

.search-wrapper.search-header .search button {
    padding: 9px 18px;
    width: unset;
    float: right;
    margin-top: 0px;
}

.search-wrapper.search-header .glyphicon {
  font-size: 2.2rem;
}

/***** Breadcrumbs *****/
.breadcrumbs {
    font-size: 14px;
    margin-bottom: 35px;
}

.breadcrumbs a {
    color: #0000FD;
}

.breadcrumbs a:hover {
    text-decoration: underline;
    color: #0000FD!important;
}



/* HOME */
.home-hero-bg {
    background: url('/images/sps-baugruppen-mobile-bg.jpg');
    background-size: cover;
   /* background-attachment: fixed;*/
    color: white!important;
    min-height: calc(100vh - 53px); /* -navbar height */
    padding-top: 55px;
}

.desktop {
  display: none; /* hide on mobile viewport */
}

.search-home.mobile input {
  width: 80%;
  padding: 12px 15px;
}

.order-process-icon {
  width: 120px;
}

/* Home Shop Categories */
.category-button-wrapper {

}

.category-button {
    width: 100vw;
    margin-bottom: 25px;
    overflow: hidden;
}

.category-button-left-split {
    background: #0C131F;
    color: white;
    width: calc(100% - 90px);
    padding: 25px 25px;
    font-size: 1.8rem;
    text-align: center;
    border-radius: 7px 0 0 7px;
    font-weight: bold;
    float: left;
}

.category-button a:hover {
  color: red;
  text-decoration: none;

}

/* button arrow right part */
.category-button:after {
  content: ">";
  float: left;
    background: #DD0002;
    padding: 25px 10px;
    border-radius: 0 7px 7px 0;
    color: white;
}


.col-md-4, 
.col.col-md-6 {
  margin-bottom: 45px;
}

.col.col-md-4:last-child,
.col.col-md-6:last-child
{  /* preventing last item margin */
    margin-bottom: 0;
}


/* CTA button variant child */

.cta-button .category-button {
  width: 100%;
}

.cta-button .category-button-left-split {
    background: #0C131F;
    color: white;
    width: calc(100% - 77px);
    margin-top: 15px;
    margin-left: 25px;
}

.cta-button .category-button:after {
  margin-top: 15px;

}





/* Services */

.services {
    padding-bottom: 35px;
}


.services .single-service {
    position: relative;
    width: 100%;
    height: 250px;
    background: white;
}

.services .single-service .service-ups {
    width: 100%;
    height: 100%;
    background: url('/images/black-and-white-ups-logo.png');
    background-size: 90px 106px;
    background-repeat: no-repeat;
    background-position: center;
}

.services .single-service .service-stock {
    width: 100%;
    height: 100%;
    background: url('/images/checkmark-outline.png');
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position: center;
}

.services .single-service .service-warranty {
    width: 100%;
    height: 100%;
    background: url('/images/warranty-icon-png-1.png');
    background-size: 124px 120px;
    background-repeat: no-repeat;
    background-position: center;
}

.single-service p {
    position: absolute;
    top: 73%;
    left: 0;
    width: 100%;
    padding: 0 50px;
    text-align: center;
}

/* Home Categories */

section.home-categories .col {
    margin-right: 0!important;
    width: 25%;
    padding-left: 0;
}

.home-categories .single-category {
    width: 100%;
    height: 220px;
    text-align: center;
    color: white;
}



.home-categories .single-category h2 {
    line-height: 220px;
    margin: 0;
}

.home-categories .single-category.category-s5 {
    background: url('/images/category-s5-bg_optm.jpg');
    background-position: -535px;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(90%);
}

.home-categories .single-category.category-s5:hover {
    filter: brightness(100%);
}
.home-categories .single-category.category-s7:hover {
    opacity: 0.95;
}



.home-categories .single-category.category-s7 {
    background: #1A2434;

}

#requestProduct, #orderProduct {
  z-index: 9999;
}


/*** Home Product Info ***/
section.home-product-info {
  background: #EFEFEF;
}

section.home-product-info img {
  height: 90px;
}

section.home-popular-products {
    width: 100%;
    padding: 55px calc(100% - 1022px);
    overflow: hidden;
}

section.section-grey {
  background: #EFEFEF;
}


section.section-cta h2 {
  text-align: center;
}




/* -------------------------------------------*/
/*  Product Listing
/* -------------------------------------------*/

.product-listing .single-product {
    /*width: 50%;
    float: left;*/
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    margin-bottom: 25px;
}

.product-listing .single-product:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.product-listing .single-product .image-wrapper {
}

.product-listing .single-product .image-wrapper img {
    width: 100%;
}

.product-listing .single-product .info {
  white-space: normal;
  padding: 15px;
}

.product-listing .single-product .title {
    line-height: 23px;
}

.product-listing .more-link a {
        color: black;
        font-weight: 600;
}

/* 2 Column Listing */
.product-listing.col-2 .single-product {
    width: calc(50% - 15px);
    margin-right: 15px;
    float: left;
    height: 295px;
}

/* Horizontal Listing with scroll x */
.product-listing.horizontal-scroll {
    width: 100%;
    overflow: auto!important;
    white-space: nowrap;
}

.product-listing.horizontal-scroll .single-product {
  display: inline-block;
  white-space: nowrap;
  float: none;
  width: 60%;
  margin-right: 15px;
}

/* -------------------------------------------*/
/*  Product Page
/* -------------------------------------------*/

.container-product-page {
  display: flex;
  flex-flow: column;
}

/* Product Image */

.product-image-wrapper {
    margin-top: 25px;
    margin-bottom: 10px;
    order: 2;
}

/* Product Info */
.wrapper-outline .product-info {
  order: 1;
}

/* Main */


.product-image-wrapper img {
    width: calc(33% - 7px);
    margin-right: 5px;
    margin-bottom: 15px;
}
.product-image-wrapper a:first-child img,
#requestProduct .product-image-wrapper a:first-child img,
#orderProduct .product-image-wrapper a:first-child img  {
    width: 100%;
    margin-bottom: 25px;
}



#requestProduct .product-image-wrapper img,
#orderProduct .product-image-wrapper img {
  width: 100px;
}

.btn-preisanfragen {
    background: #3f5e90;
   
    border: none;
    font-size: 0.9em;
    font-weight: 400;
    letter-spacing: 0.01em;
    padding: 12px 15px;
    margin-bottom: 15px;
}


/* Product Infos (Bootstrap Tabs) */
div#exTab1 {
    padding: 0!important;
}


#exTab1 img {
    width: 18px;
    margin-left: 5px;
    margin-top: -2px;
}

/* active tab */

.nav-pills>li a {
    background: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    color: black;
    padding: 7px 12px;
    font-weight: 700;
    border-radius: 5px;
    font-size: 1.3rem;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus {
  background: transparent;
  border: 1px solid black;
  color: black;
}



.nav-pills>li+li {
    margin-bottom: 25px;
    margin-left: 7px;
}

/** Product Info Table (from Tabs) **/
.product-info-table {
  margin-bottom: 25px;
}

.product-info-table .row {
  padding: 0;
}

.product-info-table .col {
  background: white; padding: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  padding-left: 17px;
  padding-right: 17px;
  font-size: 1.3rem;
}


.product-info-table .col-xs-offset-1 {
        margin-left: 5px;
    }


/***** Product Description *****/
.product-description-wrapper {
    font-size: 13px;
    line-height: 1.9em;
    width: 70%;
}


.product-description-wrapper p {
    padding: 0px!important;
}

.product-details {
    font-size: 15px;
    font-weight: bold;
    padding: 0!important;
    margin-top: 35px;
    margin-bottom: 35px;
}

.btn-preisanfragen {
    margin-right: 20px;
    background: #C91E1E!important;
    border-radius: 5px;
    padding: 15px 31px;
    width: 100%;
}

.btn-preisanfragen:hover,
.btn-primary:hover  {
   background: black!important;
}

.show-product-price {
     margin-top: 42px;
    font-weight: bold;
    font-size: 21px;
}

.close {
    font-size: 28px;
    color: #000;
    opacity: 1;
}

#requestProduct .modal-body,
#orderProduct .modal-body  {
  padding-top: 0;
}

#requestProduct .row,
#orderProduct .row {
  padding-top: 0;
}


#requestProduct .row,
#requestProduct .col,
#orderProduct .row,
#orderProduct .col {
  padding-left: 0;
  padding-right: 0;
}

#requestProduct .product-image-wrapper,
#orderProduct .product-image-wrapper {
  display: none;
  padding-top: 0;
}


/***** Product Availability *****/
.product-availability-wrapper {
    clear: both;
    margin-top: 45px;
}

.product-availability-wrapper small {
    font-size: 10px;
}


.wrapper-outline {
   /* background: white;
    margin-bottom: 15px;*/
   /* padding: 25px 20px;*/
    /*border: 1px solid #EBEBEB;*/
}

.office-image img {
    width:100%;
    margin-bottom: 25px;
}

.product-availability-wrapper label {
    margin-bottom: 35px;
}



/*** Table ***/

.table-head td {
    background: #F0F0F0;
    font-weight: bold;
}

table td {
    border: 1px solid #E2E2E2;
    padding: 5px 5px;
    font-size: 13px;

}

.delivery-info img {
    height: 55px;
    margin: 25px 0;
}

.row {
    width: 100%;
    padding: 35px 15px;
    margin: 0!important;
}


.col {
   /* width: 100%;*/
    /*float: none;*/
    /*margin-bottom: 75px;*/
    
}




/* -------------------------------------------*/
/*  Contact
/* -------------------------------------------*/

section.section-contact {
  background: url('/images/contact-page-bg.png');
  background-repeat: no-repeat;
  background-position-y: -50vh;
}

.section-contact .container {
  background: white;
  border-radius: 10px;
  padding: 35px 15px;
  margin-top: 25px;
}

#contact-form input,
#contact-form textarea {
      background: #F4F4F4;
      border: none;
      border-radius: 5px;
      padding: 7px 25px;
      font-size: 1.4rem;
    }

#contact-form input {
    width: 100%;
    height: 55px;
    margin-bottom: 15px;

}


#contact-form textarea {
    width: 100%;
    height: 130px;
    margin-bottom: 15px;
    padding-top: 15px;
}




.btn-contact {
    width: 100%;
    background: #C91E1E;
    border: none;
    border-radius: 5px;
    color: white;
    height: 55px;
    margin-bottom: 15px;
}


.btn-contact:hover {
    background: #EB3B3B;
}

.location-map {
    width: 100%;
    padding: 0;
    /*height: 500px;*/
    /*background: url('/images/map-office.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: -188px;*/
}


/* -------------------------------------------*/
/*  Category Listing
/* -------------------------------------------*/

.category-listing .single-category {
    padding: 0;
    border-bottom: 1px solid #D9D9D9;
    clear: both;
    overflow: hidden;
}

.single-category h2 {
    width: 100%;
}

.category-listing .single-category .see-more {
  float: left; 
  margin-top: 0px; 
  margin-bottom: 0;
  color: black; 
  text-decoration: underline;
}






/* -------------------------------------------*/
/*  Footer
/* -------------------------------------------*/

footer {
    
    background: #0C131F;
    width: 100%;
    color: white;
    text-align: center;
    clear: both;
    padding: 35px;
}

footer ul {
    list-style-type: none;
    padding: 0;
}

footer li {
    padding: 1rem;
}

footer a {
    color: white;
    text-decoration: underline;
}

footer b {
  /*color: #DD0002;*/
  color: white;
  font-weight: 700;
}

footer .col {
    margin-bottom: 35px;
}

/*footer .email-icon {
    width: 70px;
    height: 70px;
    margin: 50px auto;
   
   
    background-size: cover;
    background-repeat: no-repeat;
}

.footer-copyright {
    padding: 15px 25px;
    background: white;
    font-size: 13px;
    text-align: center;
}*/



/* DESKTOP VIEWPORT */

@media only screen and (min-width: 868px) {

    body {
        background: #F4F4F4;
    }

    h1 {
        
        font-size: 3.7rem;    }

    h2 {

      font-size: 2.9rem; 
     
        
    }

    h3 {
        margin-bottom: 5px;
        font-size: 20px;
    }

    /* Max width for all texts. We do not want long lines overall! */
    h1, h2, h3, p {
     /* max-width: 750px;*/
    }

    p.avoid-longlines {
      max-width: 700px; /* max line width ensuring readable paragraphs */
    }

    section {
      padding: 55px 95px;
    }

    .navbar {
      padding: 5px 0;
    }

    #floating-phone-button {
      /*display: none; /* disabled for now
      position: fixed;
      /*top: 14px;*/
      top: 87px;
      right: 85px;
      padding: 17px;
      z-index: 999;
      width: 60px;
      height: 60px;
      border-radius: 0 0 5px 5px;
      background: #C91E1E;
    }

    #floating-phone-button:hover {
      cursor: pointer;
    }

    #floating-phone-button .glyphicon {
      color: white;
      font-size: 2.5rem;
    }

    #header-contact-info {
      display: block;
    }

  #header-contact-info ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

  #header-contact-info li {
      padding: 2px 0;
      float: left;
      padding-right: 25px;
  }

  #header-contact-info li:first-child {
      border-right: 1px solid #fff;
      margin-right: 25px;
  }

  #header-contact-info li a {
      color: white;
      font-weight: medium;
      font-size: 1.8rem;
  }

  #header-contact-info .glyphicon {
      top: 2px;
      margin-right: 3px;
  }

  #toggle-header-search {
    top: 30px;
    right: 105px;
  }

  .search-wrapper.search-header {
      width: 400px;
      float: none;
      position: fixed;
      margin-top: 19px;
      padding: 0;
      background: transparent;
      right: 100px;
      left: unset;
      top: 0;
  }


  .desktop {
    display: block;
  }

  .mobile {
    display: none; /* hide mobile search */
  }

  .btn-black {
    background: black;
    color: white;
    border-radius: 5px;
  }

  .btn-black:hover {
    color: white;
  }

  .cta-button .category-button-left-split {
    width: 255px;
  }

  .category-button-left-split {
    font-size: 2.2rem;
  }

   

    .search input {
        width: calc(80% - 15px);
        background: #ededed;
        padding: 13px 15px;
        border-radius: 5px;
    }

    .search button {
        width: 20%;
        float: right;
    }


    /* resetting css bootstrap grit */
    .container {
        width: 100%;
    }


    /* Helper Class for single elements, e.g hr */
    .big-screen-disappear {
        display: none;
    }


    /* WHOLE PAGE WRAPPER */
    .page-wrapper {
        width: 100%;
       /* background: white;*/
        -webkit-box-shadow: 2px 1px 10px #373737;
    }

    /* Sidebar */
    #sidebar {
        display: block;
        position: fixed!important;
        top: 86px;
        bottom: 0;
        overflow-y: scroll;
        position: relative;
        float: left;
        z-index: 999;
        width: 200px;
        margin-left: 0;
        background: #EFEFEF;
        color: black;
        border-radius: 0 10px 0 0;
        padding: 25px 0;
    }

    #sidebar ul {
        padding: 0;
        list-style-type: none;
    }

    #sidebar li {
        background: red;
        padding: 0 5px;
        font-size: 13px!important;
        background: rgba(255,255,255,0.1);
        padding: 4px 25px;
        border-bottom: 1px solid #C0C0C0;
        font-size: 14px;
    }

    #sidebar a {
        color: black;
        text-decoration: none;
    }

    #sidebar li:hover  {
        cursor: pointer;
        color: #911;
        background: rgba(255,255,255,0.5);
    }

    .content-wrapper {
      float: left;
      width: 100%; /* view width - sidebarWidth */
      position: relative;
      padding-top: 25px;
      padding-left: 200px;
  }
    .content-wrapper .container {
        /*padding: 40px 90px!important;*/
  
    }

    /* Navigation Bar */

    .navbar-header {
      width: 258px; /* fixing right margin issue */
    }

    .navbar-nav {
        padding: 0;
        padding-right: 25px;
        margin-left: 12px;
    }

    .navbar-nav li {
        padding-top: 15px;
    }

    .navbar-nav li.active  {
        background: transparent;
    }

    .navbar-nav li.active a  {
        background-color: transparent!important;
        color: white!important;
    }

    /* Logo */
    .navbar-default .navbar-brand {
        /*background: url('/images/logo.png');
        background-size: cover;*/
        width: 130px;
        height: auto;
        margin-top: 0px;
        margin-left: 15px!important;
        margin-right: 150px;
    }

    

    .container p {
        padding: 25px;
    }

    /* Home */
    .home-hero-bg {
      /*padding-top: 80px;
      padding-left: 100px;
      padding-right: 100px;*/
      background: url('/images/sps-baugruppen-main-image.png');
      background-size: cover;
      min-height: calc(100vh - 100px); /* -navbar height */
  }


  .category-button {
    width: unset;
  }

  .category-button-left-split {
     width: calc(100% - 30px);

  }
  /* CTA */

  section.section-cta {

  }

  section.section-cta h2 {
    text-align: left!important;
  }






    /* Services */
    .services .single-service {
        width: 33%;
        height: 280px;
        float: left;
    }

    .services .single-service p {
        top: 65%;
    }

    .services .single-service .service-ups,
    .services .single-service .service-stock,
    .services .single-service .service-warranty {
        background-position: center 57px;
    }

    /* Categories Home */
    .home-categories .single-category {
        width: 50%;
        float: left;
    }

    .home-categories .single-category.category-s5 {

        background-position: -389px;
    }

    /* Product Listing */
    .product-listing .single-product {
      position: relative;
      clear: none;
      float: left;
      border: 1px solid #DDDDDD;
      border-radius: 7px;
      width: calc(100% / 3 - 40px);
      height: unset;
      margin-right: 40px;
      margin-bottom: 25px;
      padding: 0;
    }


    .product-listing .single-product .image-wrapper {
        float: none;
        height: 200px; /* cropping the image */
        overflow: hidden;
    }

    .product-listing .single-product .image-wrapper img {
        width: 100%;
        filter: brightness(90%);
    }

    .product-listing .single-product .info {
        padding: 20px 8px;
    }

    .product-listing .single-product .title {
        position: absolute;
        width: 200px;
        margin: 0 auto;
        text-align: center;
        top: 55px;

    }

    .product-listing .single-product .title a {
        color: white;
    }

    .more-link {
        line-height: 1.5em;
    }

    /* Product Page */
    .product-image-wrapper {
        width: 45%;
        float: left;
        margin-top: 0;
        margin-right: 45px;
    }


    .product-listing.col-2 .single-product {
      width: calc(30% - 15px);
      height: 295px;
  }

  .nav-pills>li a {
    font-size: 1.3rem;
  }

    

    /* Product Request Modal */

    .modal-dialog {
      margin-top: 7vh;
      height: 90vh;
    }

    .modal-content {
        background: #F4F4F4;
        border-radius: 4px!important;
        padding: 0 35px;
        border: none;
        box-shadow: 0 5px 15px rgb(0 0 0 / 20%);

    }

    .modal-footer {
      padding-left: 6px;
    }

    .modal-dialog .row {
      padding: 0;
    }



  .modal-backdrop.in {
      filter: alpha(opacity=40);
      opacity: .4;
  }

    .modal-header {
        border-bottom: none;
        padding: 30px 0px;
        padding-left: 16px;
    }

    

    .modal-title {
      font-weight: bold;
      font-size: 2.1rem;
    }





    #requestProduct .product-condition-info,
    #orderProduct .product-condition-info {
        margin-bottom: 15px;
    }

    #requestProduct #exTab1,
    #orderProduct #exTab1 {
      margin-top: 25px;
    }

    #requestProduct .product-image-wrapper,
    #orderProduct .product-image-wrapper {
      display: block;
      width: unset;
    }

    #requestProduct .contact-form,
    #orderProduct .contact-form {
      margin-top: -55px;
      padding-left: 35px;
    }

    #requestProduct .contact-form h3,
    #orderProduct .contact-form h3 {
      margin-bottom: 15px;
    }

    #requestProduct .contact-form input,
    #requestProduct .contact-form textarea,
    #orderProduct .contact-form input,
    #orderProduct .contact-form textarea {
      background: white;
      padding: 7px 25px;
      border: none;
      border-radius: 5px;
      text-align: left;
      border-bottom: 1px solid #acacac;
    }


    #requestProduct .contact-form input,
    #orderProduct .contact-form input
     {
      height: 45px;
      font-size: 1.3rem;
    }

    #requestProduct .contact-info,
    #orderProduct .contact-info {
      float: left;
      font-size: 1.8rem;
      font-weight: normal;
    }
    #requestProduct .contact-info span,
    #orderProduct .contact-info span {
      margin-top: 3px;
      margin-left: 7px;
    }

    #requestProduct .contact-info a,
    #orderProduct .contact-info a {
      margin-right: 15px;

    }

    .lightbox {
      position: fixed!important;
      top: 20vh!important;
    }

    .product-info {
        width: 45%;
        float: left;
        overflow-y: scroll;
        /*height: 320px;*/
        background: transparent;
        margin-top: -7px;
    }

    .product-info p {
        padding-left: 4px;
    }

    table td {
         padding: 5px 15px;
         line-height: 1.3em;
 
    }

    .wrapper-outline {
        padding: 0px 20px;

    }

    .container-product-page {
      display: block;  /* changing to block! before: flex; */
      padding-top: 55px!important;
    }

    .container-shop-pages {
      padding-top: 55px!important;
    }


    .row {
        width: 100%;
        padding: 35px 15px;

    }

    .col {
        /*width: calc(33% - 35px);*/
        float: left;
        /*margin-right: 35px;*/
        
    }


    /* Shop */
    .category-listing .single-category {
        padding: 35px;
        padding-left: 0;
    }

    .category-listing .single-category .see-more {
      float: right; 
      margin-top: 30px; 
      margin-bottom: 25px;
      
    }

    .product-listing.horizontal-scroll {
      width: 100vw;
    }

    .product-listing.horizontal-scroll .single-product {
      width: 17%;
    }





    /* About Us */
    .office-image img {
        
        width: 60%;
        margin-right: 25px;
        margin-bottom: 25px;
    }

    /* Contact */
    #contact-form {
        width: 450px;
        float: left;
        border-right: 4px solid #F4F4F4;
        padding-right: 25px;
        margin-right: 25px;
    }


  .section-contact .container {
    margin-top: 0;
    padding: 35px;
  }



    .location-map {

        background-position-x: center;
    }


    /* Modal Popup size */
    .modal-lg {
      width: 75%;
    }




    /* Footer */
    .footer-copyright {
        padding: 25px 25px 10px 25px;
    }

    footer {
      text-align: left;
      padding: 80px 25px 80px 280px; /* sidebar offset + content inner left padding ! */
    }


}


/*** BIG SCREENS VIEWPORT ***/

@media only screen and (min-width: 1440px) {

  body {
    font-size: 20px;
  }

  h1 {
    font-size: 4rem;
  }

  section {
    padding: 100px 11%;
  }

  p.avoid-longlines {
    max-width: 850px;
  }

  .navbar-nav {
        margin-left: 90px;
  }

  section.home-hero-bg {
    padding-top: 85px;
  }


  .category-button-left-split {
    width: calc(100% - 35px);
  }


  section.home-popular-products {
    padding: 100px 11%;  
  }

  section.section-contact {
    background: url(/images/contact-page-bg.png);
    background-repeat: no-repeat;
    background-position-y: -15vh;
    background-size: cover;
  }

  .product-listing.col-2 .single-product {
    height: 365px;
  }

  .product-listing .single-product .image-wrapper {
    height: 235px;
  }

  .modal-content {
    padding: 45px;
  }










}
